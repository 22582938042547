import { createSlice } from "@reduxjs/toolkit"

type MarkAsPromptState = {
  visible: boolean
}

const initialState: MarkAsPromptState = { visible: false }

const markAsPromptSlice = createSlice({
  name: "markAsPrompt",
  initialState,
  reducers: {
    showMarkAsPrompt(state) {
      state.visible = true
    },
    hideMarkAsPrompt(state) {
      state.visible = false
    },
  },
})

export const { showMarkAsPrompt, hideMarkAsPrompt } = markAsPromptSlice.actions
export default markAsPromptSlice.reducer
