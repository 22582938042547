enum ActionType {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",

  USER_REQUEST = "USER_REQUEST",
  USER_SUCCESS = "USER_SUCCESS",
  USER_FAILURE = "USER_FAILURE",

  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",

  PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_REQUEST_SUCCESS = "PASSWORD_RESET_REQUEST_SUCCESS",
  PASSWORD_RESET_REQUEST_FAILURE = "PASSWORD_RESET_REQUEST_FAILURE",

  PASSWORD_RESET_CONFIRM_REQUEST = "PASSWORD_RESET_CONFIRM_REQUEST",
  PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS",
  PASSWORD_RESET_CONFIRM_FAILURE = "PASSWORD_RESET_CONFIRM_FAILURE",

  PERMISSION_GROUPS_REQUEST = "PERMISSION_GROUPS_REQUEST",
  PERMISSION_GROUPS_SUCCESS = "PERMISSION_GROUPS_SUCCESS",
  PERMISSION_GROUPS_FAILURE = "PERMISSION_GROUPS_FAILURE",

  CONSUMER_MARK_AS_PROMPT_TOGGLE = "CONSUMER_MARK_AS_PROMPT_TOGGLE",

  CURRENT_SITUATION_BEEN_EXPANDED = "CURRENT_SITUATION_BEEN_EXPANDED",

  CTA_SETTINGS_REQUEST = "CTA_SETTINGS_REQUEST",
  CTA_SETTINGS_SUCCESS = "CTA_SETTINGS_SUCCESS",
  CTA_SETTINGS_FAILURE = "CTA_SETTINGS_FAILURE",

  CTA_DISMISS_REQUEST = "CTA_DISMISS_REQUEST",
  CTA_DISMISS_SUCCESS = "CTA_DISMISS_SUCCESS",
  CTA_DISMISS_FAILURE = "CTA_DISMISS_FAILURE",

  SET_BRANDING_OPTIMISTIC = "SET_BRANDING_OPTIMISTIC",
}

export default ActionType
