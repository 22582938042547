import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { Location } from "history"

type RedirectLocation = Omit<Location, "state" | "hash">

export type RedirectState = {
  location: RedirectLocation | null
}

export const initialState: RedirectState = {
  location: null,
}

export const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    setRedirect: (state, action: PayloadAction<RedirectLocation>) => {
      state.location = action.payload
    },
    clearRedirect: (state) => {
      state.location = null
    },
  },
})

export const { setRedirect, clearRedirect } = redirectSlice.actions
export default redirectSlice.reducer
