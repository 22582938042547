import { combineReducers } from "redux"

import markAsPrompt from "../../components/business/ConsumerActionBar/markAsPrompt"
import { detailActivityApi } from "../../components/business/UserActivities/services/detailActivityApi"
import productRates from "../../components/consumer/Lifecycle/ProductRatesPage/productRatesSlice"
import { businessFileManagerApi } from "../services/business/fileManager"
import { businessProductSelectionApi } from "../services/business/productSelection"
import { reportingAdvisorsApi } from "../services/business/reportingAdvisors"
import { consumerApi } from "../services/consumerApi"
import { consumerFactorApi } from "../services/consumerFactor/consumerFactorApi"
import { employeeFactorApi } from "../services/employeeFactor/employeeFactorApi"
import { lifecycleProspectsApi } from "../services/lifecycleProspects/lifecycleProspectsApi"
import { onboardingFactorApi } from "../services/onboardingFactor/onboardingFactorApi"
import { singleSignOnApi } from "../services/singleSignOn"
import advisors from "../slices/advisors"
import imageEditor from "../slices/imageEditor"
import learnSession from "../slices/learnSession"
import loadApproval from "../slices/loadApproval"
import onboarding from "../slices/onboarding"
import persistant from "../slices/persistantSession"
import productSelection from "../slices/productSelection"
import redirect from "../slices/redirect"
import ui from "../slices/ui"
import auth from "./auth"
import consumerUpdate from "./business/consumerUpdate"
import permissionGroups from "./business/permissionGroups"
import ctaPrompt from "./consumer/ctaPrompt"

const store = combineReducers({
  [businessFileManagerApi.reducerPath]: businessFileManagerApi.reducer,
  [businessProductSelectionApi.reducerPath]: businessProductSelectionApi.reducer,
  [consumerApi.reducerPath]: consumerApi.reducer,
  [consumerFactorApi.reducerPath]: consumerFactorApi.reducer,
  [detailActivityApi.reducerPath]: detailActivityApi.reducer,
  [employeeFactorApi.reducerPath]: employeeFactorApi.reducer,
  [lifecycleProspectsApi.reducerPath]: lifecycleProspectsApi.reducer,
  [onboardingFactorApi.reducerPath]: onboardingFactorApi.reducer,
  [reportingAdvisorsApi.reducerPath]: reportingAdvisorsApi.reducer,
  [singleSignOnApi.reducerPath]: singleSignOnApi.reducer,
  advisors,
  auth,
  consumerUpdate,
  ctaPrompt,
  imageEditor,
  learnSession,
  loadApproval,
  markAsPrompt,
  onboarding,
  permissionGroups,
  persistant,
  productRates,
  productSelection,
  redirect,
  ui,
})

export default store
